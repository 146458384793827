<template>
  <div id="app">
    <IndexPage msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import IndexPage from "@/components/IndexPage.vue";


export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>

<style>
html,body{
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
