const state = {
   authCode:"",
}
const mutations = {
   setAuthCode(state,authCode){
      state.authCode = authCode
   }
}
const actions = {
   setAuthCode({commit},authCode){
      commit("setAuthCode",authCode)
   }
}
export default {
   namespaced: true,
   state,
   mutations,
   actions
}