import VueRouter from 'vue-router'
import homeInfo from "@/components/HomeInfo.vue";
import myInfo from "@/components/MyInfo.vue";
import IndexPage from "@/components/IndexPage.vue"
import TaskInfo from "@/components/TaskInfo.vue"
export default  new VueRouter({
    routes:[{
      path:"*",
      component:homeInfo
    },{
        path:"/home",
        component:homeInfo
    },{
        path:"/myinfo",
        component:myInfo
    },{
        path:"/taskInfo/:taskId",
        component:TaskInfo
    }
    ]
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}