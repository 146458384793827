




<template>

  <div class="indexPage">
    <div class="info-contain">
      <router-view ></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
  components: {
  },
  props: {
    msg: String
  },
  mounted() {
  },

  created(){

  },
  data(){
    return{
      active:0
    }

  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.indexPage{
  width: 100%;
  height: 100%;
}
.info-contain{
  width: 100%;
  height:100%;
}
</style>
