<template>
    <div class="home-contain" v-if="!isShare">

    <div class="img-content">
      <img src="../assets/caishen.png" width="60%" height="100%">
      <span class="butRight" @click="myinfoShow()">我的</span>
    </div>

    <div class="item-list">
      <div v-if="list.length == 0">
        <h3 style="color: #fff;margin-top: 30px">红包已领完，明天再来！</h3>
      </div>
      <div class="item-second-list" v-else>


     <div
            v-for="(item,index) in list" :key="index"
            class="items" @click="hongbaoClick(item)"
        >
       <div class="daitijia_con" v-if="item.status == 'SUBMIT' ">
           <img src="../assets/daitijiao.png">
       </div>
          <div class="hongbao-money">
            <span class="money-item" >{{item.amount+"元"}}</span>
          </div>
         
        </div>


      </div>


    </div>

    <!--<van-overlay :show="dialogShow">
      <taskInfo ref="overlayRef"  :isvip = "true" :itemDetail="itemDetail"  @closeDialog ="closeDialog"></taskInfo>
&lt;!&ndash;      <div class="wrapper" >
         <img src="../assets/fininshed.png" width="100%" @click = "dialogShow = false">
      </div>&ndash;&gt;
    </van-overlay>-->
  </div>
    <div v-else>
        <van-empty description="加载中..." />
    </div>
</template>
<script>
import { Notify } from 'vant';
import store from "@/store";
import {AssistantApi} from "@/api/api";
const { gethongbapList,getToken,bangdingShare } = AssistantApi
 import qs from 'qs';
export default {
  name: 'HomeInfo',
  data(){
    return{
      dialogShow:false,
      list:[],
      itemDetail:{},
        isShare:false
    }
  },
  created() {
  if(!store.getters.authCode){
      this.loadAlipaySDK();
    }else{
      this.isShareUrl();
    }
      /*store.dispatch("constant/setAuthCode", "ACD65E5241714D899602D5EEFD7CDBE9xm");
  this.isShareUrl();*/
  },
  methods:{
      //判断路径是否是分享路径
      isShareUrl(){
     const queryParams = qs.parse(window.location.search.slice(1));
     if(JSON.stringify(queryParams) === '{}'){

         this.isShare = false;
       this.gethongbaoList();
       return
     }

     this.isShare = true;
     const msg = {
       inviterId:queryParams.shareId
     }
     bangdingShare(msg).then((response) => {
       ap.pushWindow("alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024102310384400001203%26businessId%3D2024102310384400001203%26businessType%3D18%26source%3DOTHERS");
     }).catch((error) => {
       console.error("请求失败:", error);
     })
   },
      //加载支付宝autcode
   loadAlipaySDK(){
      let that = this;
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
      script.onload = function() {
        ap.getAuthCode({
          appId: '2021004190685903',
          scopes: ['auth_base'],
        }, function (res) {
          that.getToken(res.authCode)
        },function (err){
        });
      }
      document.body.appendChild(script);
    },
      //根据authcode获取token
    getToken(data){

      const msg= {
        authCode : data
      }
      getToken(msg).then((response) => {
        store.dispatch("constant/setAuthCode", response.data.token);
        this.isShareUrl();
      }).catch((response) => {
        Notify({ type: 'warning', message: response.msg });
      });
    },

    //获取红包列表请求
    gethongbaoList(){
      gethongbapList("msg").then((response) => {
        this.list = response.data;
      }).catch((error) => {
        console.error("请求失败:", error);
      });
    },
      //点击红包事件
    hongbaoClick(item){
     // this.itemDetail = item;
      this.itemDetail = item;
        this.$router.push(`/taskInfo/${item.taskId}`);
    },
    myinfoShow(){
      this.$router.push('/myinfo');
    }
  }
}
</script>
<style scoped>
.home-contain{
  background: url("../assets/background.png");
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.img-content{
  position: fixed;
  width: 100%;
  height: 200px;
  margin-top: 10px;
}

.item-list{
  position: absolute;
  top: 260px;
  left: 0px;
  width: 100%;
  height: calc(100% - 200px);
  box-sizing: border-box;
  padding: 35px 20px;
  background-image:url(../assets/kuang.png);
  background-size: cover;

}
.item-second-list{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 10px;
  box-sizing: border-box;

}
.items{
  background-image: url(../assets/hongbao.png);
  background-position: center;
  width: 40%;
  background-size: cover;
  height: 150px;
  margin: 10px 10px;
  position: relative;
}
.daitijia_con{
    position: absolute;
    right: 6px;
   top: 6px;
}
.hongbao-money{
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果也需要水平居中的话 */
  top: 10%;
  font-size: 0.8rem;
  left: 0%;
  color: #F13827;

}
.money-item{
  width: 50px;
  height: 50px;
  background-image: url(../assets/hongbao_circle.png);
  background-size: cover;
  line-height: 50px;
}
.van-overlay{
  z-index: 1000;
}
.wrapper {
   width: 80%;
  height: 80%;
  margin: auto auto;
}
.butRight{
  position: absolute;
  top: 120px;
  color: #C82838;
  font-size: 16px;
  background-color: #fff;
  opacity: 0.8;
  padding: 8px 8px;
  right: 0px;
  font-weight: bold;
  border-radius: 5px 0px 0px 5px;
}
</style>