import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import Router from 'vue-router'
import router from "@/router";
import "./assets/font/iconfont.css"
import VueClipboard from 'vue-clipboard2';
Vue.use(Vant)
Vue.config.productionTip = false
Vue.use(Router)
Vue.use(VueClipboard);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
